import { opportunityClosedStates, ZERO_COUPON_BOND } from '@helpers/constants';
import OpportunityTypes from '@helpers/opportunity-types';
import { hasWindow } from '@services/http.service';
import { floor, sumBy } from 'lodash';
import Moment from 'moment';
import { theme } from 'tailwind.config';
import KycStatusEnums from './kyc-status';
const { orderBy } = require('lodash');
import { investmentFlowGaEventsName } from '@helpers/ga-events-name-enum';


export function debounce(func, wait, immediate) {
    var timeout;
    return function executedFunction() {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const getMaskedData = (data, charsToShowFromEnd = 4, mask = 'X') => {
    if (data && data.length > charsToShowFromEnd) {
        return (
            data
                ?.slice(0, data.length - charsToShowFromEnd)
                .replace(/./g, mask) +
            data?.slice(data.length - charsToShowFromEnd)
        );
    }

    return data;
};

export const parseData = (data) => {
    return JSON.parse(data);
};

export const mailToGmail = (value) =>
    `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${value}`;

export const callToNumber = (value) => `tel:${value}`;

export const getNumberWithComma = (number) => {
    return number && number.toLocaleString('en-IN');
};

export const getNumberAsCurrency = (
    number,
    locale = 'en-IN',
    currency = 'INR'
) => {
    const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,  // Set minimumFractionDigits to 0
        maximumFractionDigits: number % 1 !== 0 ? 2 : 0,  // Set maximumFractionDigits based on decimal presence
    };

    return number?.toLocaleString(locale, options);
};

export const getNumberAsCurrencyWithTwoFractionDigits = (
    number,
    locale = 'en-IN',
    currency = 'INR'
) => {
    const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    return number?.toLocaleString(locale, options);
};

export const scrollToTargetAdjusted = (reference, headerOffset = 64) => {
    let elementPosition = reference.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const getInitials = (fullName) => {
    let names = fullName.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getNumberInLakhAndCrore = (value) => {
    if (value >= 10000000000) {
        const startingValue = String(value).slice(0, 6);
        const valueToShow =
            startingValue[4] === '0'
                ? startingValue.slice(0, 4)
                : startingValue.slice(0, 4) + '.' + startingValue.slice(4, 6);
        return `${valueToShow} Cr`;
    }
    if (value < 10000000000 && value >= 1000000000) {
        const startingValue = String(value).slice(0, 5);
        const valueToShow =
            startingValue[3] === '0'
                ? startingValue.slice(0, 3)
                : startingValue.slice(0, 3) + '.' + startingValue.slice(3, 5);
        return `${valueToShow} Cr`;
    }
    const updatedValue = String(value).slice(0, 4);
    if (value < 1000000000 && value >= 100000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Cr`;
    }
    if (value < 100000000 && value >= 10000000) {
        const valueToShow =
            updatedValue[1] === '0' && updatedValue[2] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Cr`;
    }
    if (value < 10000000 && value >= 1000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Lakh(s)`;
    }
    if (value < 1000000 && value >= 100000) {
        const valueToShow =
            updatedValue[1] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Lakh(s)`;
    }
    if (value < 100000 && value >= 10000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} K`;
    }
    if (value < 10000 && value >= 1000) {
        const valueToShow =
            updatedValue[1] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} K`;
    }
    return getNumberWithComma(value);
};

export const getMinInvestmentForOpportunity = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_BOND:
        case OpportunityTypes.SDL:
        case OpportunityTypes.SOVEREIGN_GOLD_BONDS:
        case OpportunityTypes.SECURITISED_DEBT_INSTRUMENT:
        case OpportunityTypes.UNLISTED_SECURITISED_DEBT_INSTRUMENT:
        case OpportunityTypes.G_SEC:
        case OpportunityTypes.TREASURY_BILL:
            return getNumberWithComma(
                Math.round(
                    opportunityClosedStates.includes(opportunity.state)
                        ? opportunity.initialFaceValue *
                        opportunity.minInvestmentUnit
                        : opportunity.dailyDebenturePrice *
                        opportunity.minInvestmentUnit
                )
            );
    }
};

export const checkIfUpdatingMaxInvestmentUnitPossible = (opportunity) => {
    return (
        ['InDraft', 'SubcriptionReady'].includes(opportunity.state) ||
        (opportunity.state === 'InvestmentReady' &&
            opportunity.accessCode ===
            process.env.NEXT_PUBLIC_ADMIN_ACCESS_CODE)
    );
};

export const getExpectedMaturityDate = (opportunity) => {
    let expectedMaturityDate = '';
    let schedule = opportunity?.paymentSchedule?.filter(
        (schedule) =>
            schedule?.opportunityTrancheId !== null
    );
    expectedMaturityDate = schedule[0]?.formattedDate ? schedule[0]?.formattedDate : schedule[0]?.date;

    if (opportunity?.paymentSchedule.length > 1) {
        const sortedPaymentSchedule = orderBy(
            opportunity?.paymentSchedule,
            'date',
            ['desc']
        );
        expectedMaturityDate = sortedPaymentSchedule[0]?.formattedDate ? sortedPaymentSchedule[0]?.formattedDate : sortedPaymentSchedule[0]?.date;
    } else {
        expectedMaturityDate = opportunity.paymentSchedule[0]?.formattedDate ? opportunity.paymentSchedule[0]?.formattedDate : opportunity.paymentSchedule[0]?.date;
    }
    return expectedMaturityDate;
};

export const getStatusColor = (status) => {
    switch (status) {
        case 'Received':
            return 'text-semantic-success-base';
        case 'Delayed':
        case 'Failed':
            return 'text-semantic-error-base';
        case 'In Process':
            return 'text-secondary-600';
        case 'Pending':
            return 'text-semantic-warning-base';
        case 'Completed':
            return 'text-gray-800';
        default:
            return 'text-gray-500';
    }
};

export const getStatusColorImage = (status) => {
    switch (status) {
        case 'Received':
            return '/images/posEllipse.svg';
        case 'Delayed':
            return '/images/negEllipse.svg';
        case 'In Process':
            return '/images/UpcomingEllipse.svg';
        default:
            return '/images/grayEllipse.svg';
    }
};

// TODO: use single method for all assets
export const getStatusBadgeStyles = (status) => {
    switch (status) {
        case 'Received':
            return 'text-semantic-success-base bg-green-100';
        case 'Delayed':
            return 'text-semantic-error-base bg-semantic-error-light';
        case 'In Process':
            return 'text-secondary-700 bg-secondary-100';
        default:
            return 'text-gray-700 bg-gray-100';
    }
};

// TODO: use single method for all assets
export const getStatusBadgeStylesForAssetBackedLeasingAndInvoiceDiscounting = (
    status
) => {
    switch (status) {
        case 'Repaid':
            return 'text-semantic-success-base bg-green-100';
        case 'Delayed':
            return 'text-semantic-error-base bg-semantic-error-light';
        case 'Repayment in progress':
            return 'text-semantic-info-base bg-semantic-info-light';
        case 'In Process':
            return 'text-secondary-700 bg-secondary-100';
        default:
            return 'text-gray-700 bg-gray-100';
    }
};

export const amountToBeMultipleOf = (amount, investmentMultiple) =>
    amount % investmentMultiple === 0;

export const isAssetBackedLeasingOpportunity = (opportunityType) => {
    return opportunityType === 'AssetBackedLeasing';
};

export const isCorporateDebtOpportunity = (opportunityType) => {
    return opportunityType === OpportunityTypes.CORPORATE_BOND;
};

export const isVentureDebtOpportunity = (opportunityType) => {
    return opportunityType === 'VentureDebt';
};

export const isTreasuryBillOpportunity = (opportunityType) => {
    return opportunityType === OpportunityTypes.TREASURY_BILL;
};
export const isInvoiceDiscountingOpportunity = (opportunityType) => {
    return opportunityType === 'InvoiceDiscounting';
};

export const getDecimalNumberWithComma = (number, decimalPlace = 4) => {
    number = parseFloat(number).toFixed(decimalPlace);
    return number.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
};

export const checkInProcessStatus = (repaymentDate, gracePeriod = 0) => {
    let today = Moment(Moment().utcOffset(330).format('YYYY-MM-DD'));
    let repaymentDateInFormat;

    if (Moment(repaymentDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
        repaymentDateInFormat = Moment(
            repaymentDate,
            'YYYY-MM-DDTHH:mm:ss.SSSZ'
        ).format('YYYY-MM-DD');
    } else if (Moment(repaymentDate, 'DD MMM YYYY', true).isValid()) {
        repaymentDateInFormat = Moment(repaymentDate, 'DD MMM YYYY').format(
            'YYYY-MM-DD'
        );
    } else {
        repaymentDateInFormat = Moment(repaymentDate, 'MM-DD-YYYY').format(
            'YYYY-MM-DD'
        );
    }

    const gracePeriodDate = Moment(repaymentDateInFormat)
        .add(gracePeriod, 'days')
        .format('YYYY-MM-DD');

    if (
        today.isSameOrBefore(gracePeriodDate) &&
        today.isAfter(repaymentDateInFormat)
    ) {
        return 'In Process';
    }
    return 'Delayed';
};

export const getPaymentStatus = (payment, gracePeriod = 0) => {
    if (payment.status === 'Delayed') {
        return checkInProcessStatus(payment.date, gracePeriod);
    }
    return (payment?.status === 'UpComing' || !payment?.status) ? 'Upcoming' : payment.status;
};



export const formatDate = (date, format = 'MMM D, YYYY', offset = '+05:30') =>
    Moment.utc(date).utcOffset(offset).format(format);

export const formattedDate = (date, format = 'MMM D, YYYY') =>
    Moment(date).format(format);

export const downloadUrl = async (url) => {
    var link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
};

export const getNumberWithFourdecimal = (number) => {
    if (typeof number === 'string') {
        return Number(number).toFixed(4);
    }
    return number.toFixed(4);
};

export const stringifyData = (data) => {
    return JSON.stringify(data);
};

export const startTimer = (expiry, setMins, setSecs) => {
    let myInterval = setInterval(() => {
        let diff = Math.abs(expiry - new Date().getTime()) / 1000;
        setMins(String(Math.floor(diff / 60) % 60).padStart(2, '0'));
        setSecs(String(Math.floor(diff % 60)).padStart(2, '0'));
    }, 1000);
    return () => {
        clearInterval(myInterval);
    };
};

export const getMobileWithoutCountryCode = (mobile, countryCode) => {
    return mobile.replace(countryCode?.substring(1), '');
};

export const getQueryParamsFromUrl = (search, query) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(query);
};

export const isUndefined = (value) => {
    return value === undefined;
};

export const getMaskMobileNumber = (mobileNumber) => {
    return mobileNumber.replace(/^.{6}/g, '******');
};

export const getFormattedPhoneNumber = (number, countryCode) => {
    const countryCodeLength = countryCode.length;
    const phoneNumber = number.slice(countryCodeLength - 1);

    return `${countryCode} - ${phoneNumber}`;
};

export const getValidDate = (date, format = 'DD MMM YYYY') =>
    Moment(date).isValid()
        ? Moment.utc(date).utcOffset('+05:30').format(format)
        : '';

export const getTenure = (startDate, endDate) => {
    if (!startDate)
        startDate = Moment.utc().utcOffset('+05:30').format('DD MMM YYYY');
    else
        startDate = Moment.utc(startDate)
            .utcOffset('+05:30')
            .format('DD MMM YYYY');
    endDate = Moment.utc(endDate).utcOffset('+05:30').format('DD MMM YYYY');
    if (Moment(endDate).diff(Moment(startDate), 'days') < 0) {
        return '0D';
    }
    const months = Math.round(
        Moment(endDate).diff(Moment(startDate), 'days') / 30
    );

    if (months < 6) {
        const days = Math.round(
            Moment(endDate).diff(Moment(startDate), 'days')
        );
        return days + 'D';
    }
    if (months > 24)
        return `${(months / 12) | 0}Y ${months % 12 === 0 ? '' : (months % 12) + 'M'
            }`;
    return months + 'M';
};

export const getSheetData = (data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map((row) => {
        return fields.map((fieldName) => {
            return row[fieldName] || row[fieldName] === 0 ? row[fieldName] : '';
        });
    });
    sheetData.unshift(header);
    return sheetData;
};

export const getNumberWithTwoDecimal = (number) => {
    if (!number) return null;
    if (typeof number === 'string') {
        return Number(number).toFixed(2);
    }
    return number.toFixed(2);
};

export const getTaxableAmount = (amount, tax) => {
    if (amount > 0) {
        return amount * (tax / 100);
    }
    return amount;
};

export const isCounterPartyPerformanceExists = (counterPartyPerformance) => {
    return (
        counterPartyPerformance && counterPartyPerformance.totalMaturedDeals > 0
    );
};

export const isAllTransferSuccessful = (repaymentTransferRecords) => {
    return (
        repaymentTransferRecords &&
        repaymentTransferRecords.every(
            (repayment) => repayment.status === 'TransferSuccessful'
        )
    );
};

export const getPostTaxRepaymentAmount = (repaymentTransferRecords) => {
    return repaymentTransferRecords?.length
        ? sumBy(repaymentTransferRecords, 'principal') +
        sumBy(repaymentTransferRecords, 'postTaxInterest') +
        sumBy(repaymentTransferRecords, 'postTaxPenalCharges')
        : 0;
};

export const getPreTaxRepaymentAmount = (repaymentTransferRecords) => {
    return (
        sumBy(repaymentTransferRecords, 'principal') +
        sumBy(repaymentTransferRecords, 'interest')
    );
};

export const getFloorAmount = (amount) => floor(amount, 2);

export const getNestedFieldValue = (
    obj,
    fieldPath,
    defaultValue = 'N/A',
    typeOfObj = null
) => {
    const fields = fieldPath.split('.');
    let value = obj;
    for (const field of fields) {
        value = value?.[field];
        if (value === undefined || value === null) {
            return defaultValue;
        }
    }
    return typeof value === 'boolean' ? typeOfObj[value] : value;
};

export const calculatedSystemInterest = (
    discountRate,
    tenure,
    disbursedAmount
) => Number((discountRate / (365 * 100)) * tenure * disbursedAmount).toFixed(4);

export const getBorrowerPreferenceAlternativeName = (preferenceType) => {
    switch (preferenceType) {
        case 'FromDateOfFirstDisbursal':
            return 'A1';

        case 'FromDateOfLastDisbursal':
            return 'A2';

        case 'FromDateOfEachDisbursal':
            return 'A3';
        case 'SingleTranche':
            return 'B';
        default:
            return '';
    }
};

export const getBorrowerPreferenceDescription = (
    preferenceType,
    trancheTenure
) => {
    switch (preferenceType) {
        case 'FromDateOfFirstDisbursal':
            return `Borrower will repay post ${trancheTenure} days from the date of first disbursal.`;

        case 'FromDateOfLastDisbursal':
            return `Borrower will repay post ${trancheTenure} days from the date of last disbursal.`;

        case 'FromDateOfEachDisbursal':
            return `Borrower will repay post ${trancheTenure} days from the date of each disbursal.`;
        case 'SingleTranche':
            return `Borrower will receive total deal amount at once and will repay ${trancheTenure} days from the disbursal.`;
        default:
            return '';
    }
};

export const getToLocaleString = (value) => {
    if (value) return `₹ ${value.toLocaleString('en-IN')}`;
    return 0;
};

export const onAuthChange = (authToken) => {
    if (!authToken && hasWindow()) {
        if (localStorage.getItem('campaign-popup')) {
            localStorage.removeItem('campaign-popup');
        }

        if (sessionStorage.getItem('investmentStats')) {
            sessionStorage.removeItem('investmentStats');
        }
    }
};

export const getHomePageStats = (data) => {
    if (data) {
        return [
            {
                suffix: 'rupeeSymbol',
                amount: data?.entity?.investedAmount
                    ? data?.entity?.investedAmount
                    : 760,
                tagline: 'CRORE',
                title: 'TOTAL </br> INVESTMENT',
                color: theme?.colors?.pink?.[400],
                category: '',
                duration: 1.3,
            },
            {
                suffix: 'rupeeSymbol',
                amount: data?.entity?.repaidAmount
                    ? data?.entity?.repaidAmount
                    : 370,
                tagline: 'CRORE',
                title: 'REPAYMENTS </br> MADE',
                color: theme?.colors?.green?.[400],
                category: '',
                delay: 0.6,
                duration: 1.2,
            },
            {
                suffix: 'percentageSymbol',
                amount: data?.entity?.averageReturns
                    ? data?.entity?.averageReturns
                    : 12.5,
                tagline: '',
                title: 'AVG RETURNS </br> EARNED',
                color: theme?.colors?.secondary?.[500],
                category: 'PER ANNUM',
                decimalPlaces: 1,
                duration: 0.5,
                delay: 1.5,
            },
            {
                suffix: 'percentageSymbol',
                amount: data?.entity?.defaultPercentage
                    ? data?.entity?.defaultPercentage
                    : 0,
                title: 'Default percentage',
                color: theme?.colors?.leafGreen?.[300],
            },
        ];
    }

    return [];
};


export const toTitleCase = (phrase) => {
    return phrase
        ? phrase
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : '';
};

export const getIdForHtmlElement = (string) =>
    string.split(' ').join('-').replace('&', 'and').toLowerCase();

export const replaceKeysWithValues = (inputString, replacements) => {
    if (!inputString) return "";
    let resultString = inputString;

    for (const key in replacements) {
        if (replacements.hasOwnProperty(key)) {
            const regex = new RegExp(key, 'g'); // 'g' flag for global matching
            resultString = resultString.replace(regex, replacements[key]);
        }
    }

    return resultString;
}

export const showNudgeForUpdateVerifiedKycFlow = (investorKycStatus, kycFlowStatus) => {
    /*
    Hiding this for now until we bring hard nudge kyc complete
    checking liveliness, signature, esign, if any of them are pending or esign === ESignPending, than return true else false */
    return investorKycStatus === KycStatusEnums.VERIFIED && (['liveliness', 'signature', 'esign'].some((field) => kycFlowStatus?.[field] === KycStatusEnums.PENDING) || kycFlowStatus?.esign === KycStatusEnums.ESIGN_PENDING);
}

export const showNudgeInManualVerification = (investorKycStatus, kycFlowStatus) => {
    /* checking kycStatus === ManualVerificationRequired and liveliness, signature are verified than return true else false */
    return investorKycStatus === KycStatusEnums.MANUAL_VERIFICATION_REQUIRED && (['liveliness', 'signature'].every((field) => kycFlowStatus?.[field] === KycStatusEnums.VERIFIED))
}

export const getApiErrorMessage = (message) => {
    return Array.isArray(message) ? message?.[0] : message
}

export const triggerCustomGaEvent = (eventName, values) => {
    if (window?.dataLayer)
        window.dataLayer.push({ 'event': eventName, ...values });
}

export const getGAInvestmentStatusNameByStatus = (status) => {
    switch (status) {
        case 'PaymentFailed':
        case 'PaymentPending':
            return investmentFlowGaEventsName.VIEW_AMO_CASHFREE_FAILED_PAYMENT_PAGE;
        case 'PaymentCompleted':
            return investmentFlowGaEventsName.VIEW_AMO_CASHFREE_SUCCESS_PAYMENT_PAGE;
        case 'PaymentInProgress':
            return investmentFlowGaEventsName.VIEW_AMO_CASHFREE_PAYMENT_IN_PROGRESS_PAYMENT_PAGE;
        default:
            return investmentFlowGaEventsName.VIEWED_ORDER_CONFIRM;
    }
}

export const isValidOnOrAfterPresentDate = (date, setShowWarningModal) => {
    const currentDate = Moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    if (Moment(currentDate).isSameOrBefore(Moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ'))) {
        return true;
    }
    setShowWarningModal(true);
    return false;
};

export const isCurrentTimeWithinLastMinsOfTimeStamp = (timestamp, lastMins) => {
    const currentDate = Moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    if (Moment(currentDate).isSameOrAfter(Moment(timestamp).subtract(lastMins, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSSZ'))) {
        return true;
    }
   return false;
};

export const saveItemToLocalStorage = (key, details) => {
    return localStorage.setItem(key, JSON.stringify(details));
};

export const getItemFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const numberOfDaysBetweenTwodates = (
    firstDate,
    secondDate = Moment()
) => {
    const date1 = Moment(firstDate).utc().utcOffset('+05:30').startOf('day');
    const date2 = Moment(secondDate).utc().utcOffset('+05:30').startOf('day');
    const daysDiff = date1.diff(date2, 'days');
    return daysDiff;
};

export const checkDateIsSameOrAfter = (
    firstDate,
    secondDate
) => {
    const date1 = Moment(firstDate).utc().utcOffset('+05:30').startOf('day');
    const date2 = Moment(secondDate).utc().utcOffset('+05:30').startOf('day');
    const isDateAfter = date1.isSameOrAfter(date2);
    return isDateAfter;
};

export const checkDateIsBeforeOrSame = (
    firstDate,
    secondDate
) => {
    const date1 = Moment(firstDate).utc().utcOffset('+05:30').startOf('day');
    const date2 = Moment(secondDate).utc().utcOffset('+05:30').startOf('day');
    const isDateBefore = date1.isSameOrBefore(date2);
    return isDateBefore;
};

export const countDecimals = (value) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return 0;
    }
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
};

export const appendCountryCodeAndFormatMobile = (mobile, countryCode) => {
    const countryCodeWithoutPrefix = countryCode.startsWith('+') ? countryCode.substr(1) : countryCode;
    return `+${countryCodeWithoutPrefix} ${mobile.substr(countryCodeWithoutPrefix.length)}`;
}

export const maskEmail = (email) => {
    if (!email) return '';

    const [localPart, domain] = email.split('@');

    let maskedLocalPart;

    // If local part has only one or two characters, mask all
    if (localPart.length < 3) {
        maskedLocalPart = '*'.repeat(localPart.length);
    }
    // If local part has only three characters, mask all except first char
    else if (localPart.length === 3) {
        maskedLocalPart = `${localPart[0]}${'*'.repeat(localPart.length - 1)}`;
    } else {
        // Masking the local part, leaving the first and last character visible
        maskedLocalPart = `${localPart[0]}${'*'.repeat(localPart.length - 2)}${localPart[localPart.length - 1]}`;
    }
    // Reconstruct the email with the masked local part and the domain
    return `${maskedLocalPart}@${domain}`;
}

export const appendCountryCodeAndMaskMobile = (mobile, countryCode) => {
    const nbsp = String.fromCharCode(160); // Non-breaking space
    return `+${countryCode}${nbsp}${'*'.repeat(
        Math.max(
            0,
            mobile?.length -
            countryCode?.length -
            3
        )
    )}${mobile.substr(-3)}`;
};

export const getBlogMetaKeywords = (title, categories) => {
    // Common words to be removed
    const commonWords = new Set([
        'of', 'or', 'in', 'on', 'is', 'you', 'and', 'the', 'a', 'an', 'to', 'for', 'with',
        'how', 'why', 'what', 'when', 'where', 'which', 'who', 'whom', 'this', 'that', 'these', 'those', ','
    ]);

    // Function to split the title into words and filter out common words
    const processTitle = (title) => {
        return title?.toLowerCase()?.split(/\s+/)?.filter((word) => !commonWords.has(word)) || []; // Remove common words
    };

    // Process the title
    const titleKeywords = processTitle(title);

    // Process the categories by splitting and trimming each category
    const categoryKeywords = categories ? categories.split(',').map((category) => category?.trim()?.toLowerCase()) : [];

    // Combine title, title keywords and category keywords and remove duplicates
    const keywords = [...new Set([title?.toLowerCase(), ...titleKeywords, ...categoryKeywords])];

    // Return the keywords as a comma-separated string
    return keywords.join(', ');
};
// This function is intentionally left blank and serves as a placeholder.
// It can be used in scenarios where a no-operation (no-op) function is needed,
// for example, as a default function prop in React components.
export const idleFunction = () => { };


export const positionChatBotWithAuth = (width) => {
    const frameElement = document.getElementById('fc_frame');
    if (frameElement) {
        if (width < 1280) {
            frameElement.style.bottom = '68px';
        } else {
            frameElement.style.bottom = '15px';
        }
    }
}

export const positionChatBotWithOutAuth = () => {
    const frameElement = document.getElementById('fc_frame');
    if (frameElement && frameElement.style.bottom === '68px') {
        frameElement.style.bottom = '15px'; // Remove the inline style
    }
}

export function isValidJSON(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

export const isAdminOnlyDeal = (opportunityAccessCode) => opportunityAccessCode === process.env.NEXT_PUBLIC_ADMIN_ACCESS_CODE;
/*
    formatSecondsToTimeString(86400) // "24 hours"
    formatSecondsToTimeString(10800) // "3 hours"
    formatSecondsToTimeString(12600) // "3 hours 30 mins"
    formatSecondsToTimeString(12630) // "3 hours 30 mins"
    formatSecondsToTimeString(1800) // "30 mins"
    formatSecondsToTimeString(1830) // "30 mins"
    formatSecondsToTimeString(60)    // "1 min"
    formatSecondsToTimeString(50)    // "50 seconds"
*/
export const formatSecondsToTimeString = (seconds) => {
    if(!seconds) return '';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hours > 0) {
        if (minutes > 0) {
            return `${hours} hours ${minutes} mins`;
        } else {
            return `${hours} hours`;
        }
    } else if (minutes > 0) {
        return `${minutes} ${minutes > 1 ? 'mins' : 'min'}`;
    } else {
        return `${secs} seconds`;
    }
}


export const determineAction = (
    isSignup,
    opportunityId,
    isLoggedIn,
    issuer
) => {
    const descriptionPrefix = (
        <>
            Thank you for expressing interest in{' '}
            <span className="p4-semibold">{issuer}</span>.
        </>
    );

    const commonModal = {
        title: 'Interest Recorded Successfully',
        primaryButtonText: 'Ok',
    };

    if (isSignup && !opportunityId) {
        return [
            'SHOW_MODAL',
            {
                ...commonModal,
                description: (
                    <>
                        {descriptionPrefix} We will reach out to you once the
                        deal is live. Meanwhile complete your KYC to start
                        investing.
                    </>
                ),
            },
        ];
    } else if (!isSignup && !opportunityId) {
        return [
            'SHOW_MODAL',
            {
                ...commonModal,
                description: (
                    <>
                        {descriptionPrefix} We will reach out to you once the
                        deal is live. Meanwhile you can explore other
                        opportunities.
                    </>
                ),
                primaryButtonText: isLoggedIn ? 'Explore Live Opportunities' : 'Ok',
                secondaryButtonText: isLoggedIn ? 'Back' : null,
            },
        ];
    }

    return ['NO_ACTION'];
};



/**
 * Converts a string with separate words into PascalCase with spaces between words.
 * @param {string} str - The input string to convert.
 * @returns {string} - The converted string in PascalCase with spaces.
 */
export const toPascalCaseWithSpaces = (str) => {
    if (typeof str !== 'string') {
        throw new TypeError('Input must be a string');
    }
    return str
        .toLowerCase() // Convert to lowercase for consistent capitalization
        .replace(/(?:^|[\s_\-]+)(\w)/g, (_, c) => (c ? ` ${c.toUpperCase()}` : '')) // Add space and capitalize letters
        .trim(); // Remove any leading/trailing spaces
};
// Example Usage
// console.log(toPascalCaseWithSpaces("hello world")); // Output: "Hello World"
// console.log(toPascalCaseWithSpaces("snake_case_example")); // Output: "Snake Case Example"
// console.log(toPascalCaseWithSpaces("kebab-case-example")); // Output: "Kebab Case Example"
// console.log(toPascalCaseWithSpaces("multiple   spaces"));  // Output: "Multiple Spaces"
// console.log(toPascalCaseWithSpaces("_leading_underscores")); // Output: "Leading Underscores"


export const isDOBMinor = (dateOfBirth) => {
    const today = Moment();
    const dob = Moment(dateOfBirth, "YYYY-MM-DD");
    const age = today.diff(dob, 'years');

    return age < 18;
};

export const isZeroCouponBond = (displayAssetClass) => {
    return displayAssetClass === ZERO_COUPON_BOND
}